import { useEffect, useState } from "react";

const useConfFetch = () => {
  const [conf, setConf] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchConf = async () => {
    setError(false);

    try {
      const result = await fetch("/conf.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => res);
      setLoading(false);
      setConf(result);
    } catch (error) {
      setLoading(false);
      setError(true);
      setConf(error);
    }
  };
  useEffect(() => {
    fetchConf();
  }, []);

  return [conf, error, loading];
};

export { useConfFetch };
