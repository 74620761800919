import { makeStyles } from "@material-ui/core/styles";
import img360 from "./360-icon.png";
import sunIcon from "./sun-icon.png";
const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: "#f2f2f2",
  },
  contentTop: {
    // height: 220,
  },

  leftContent: {
    backgroundColor: theme.palette.green.main,
    "& p": { color: theme.palette.white.main },
    padding: 25,
  },
  codeAnnonce: {
    fontWeight: 400,
    fontSize: "0.9rem",
  },
  titleAnnonce: {
    fontSize: 28,
    fontWeight: 500,
  },

  rightContent: {
    backgroundColor: theme.palette.violet.main,
    color: theme.palette.white.main,
    padding: 25,
    "& p, a": { color: theme.palette.white.main },
  },
  imgContainer: {
    // height: 455,
    margin: "60px 0px",
  },
  detailsAndPlanLinks: {
    fontWeight: 400,
    fontSize: "0.9rem",
    marginTop: 30,
  },

  // Logo 360 & luminosité
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  logo360: {
    color: "#FFFFFF",
    width: "50px",
    height: "50px",
    display: "block",
    background: "#5D55AE url(" + img360 + ") no-repeat center",
    backgroundSize: "cover",
    margin: 10,
  },
  sunIcon: {
    color: "#FFFFFF",
    width: "50px",
    height: "50px",
    display: "block",
    background: "#5DB991 url(" + sunIcon + ") no-repeat center",
    backgroundSize: "cover",
    margin: 10,
  },

  // Carousel
  carouselContainer: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    "& div": {
      width: "fit-content",
      margin: "auto",
    },
  },
  carousel: {
    "& div": {
      width: "80vw",
      height: 700,
      [theme.breakpoints.down("md")]: {
        width: "90vw",
      },
      [theme.breakpoints.down("xs")]: {
        height: 400,
      },
    },
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    // [theme.breakpoints.up("xs")]: {
    //   width: "100%",
    // },
    // [theme.breakpoints.up("md")]: {
    //   width: "80%",
    //   // marginLeft: "10%",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   width: "100%",
    //   height: "100%",
    //   objectFit: "contain",
    // },
  },

  dots: {
    height: "25px !important",
    width: "90vw",
    "& li": {
      width: "30px",
      "& .BrainhubCarousel__dot": {
        width: "auto",
      },
    },
  },

  // Table
  table: {
    backgroundColor: "#ECF2F9",
  },

  button: {
    height: 41,
    backgroundColor: theme.palette.violet.main,
    color: theme.palette.white.main,
    padding: "10px 25px",
    fontSize: "1rem",
    textTransform: "none",
    marginBottom: "3rem",
    "& a": {
      color: theme.palette.white.main,
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },

  // Bottom part
  title: {
    fontWeight: 400,
    fontSize: "2rem",
    marginBottom: "0.5rem",
    marginTop: "2rem",
  },
  paragraph: {
    marginBottom: "2rem",
  },

  // Diagnostics
  noteContainer: {
    listStyle: "none",
    paddingLeft: "0px",
    margin: "0 0 20px",
    "& li": {
      listStyleType: "none",
      lineHeight: "21px",
      padding: "3px 5px",
      fontSize: "11px",
      borderBottom: "1px solid #fff",
      position: "relative",
      "&::before": {
        borderStyle: "solid",
        borderWidth: "14px",
        content: '""',
        position: "absolute",
        top: "0",
      },
    },
  },
  valeurDpe: {
    backgroundColor: "#444",
    borderRadius: "4px",
    color: "#fff",
    float: "right",
    fontSize: "14px",
    fontWeight: "700",
    margin: "-4px 0 0 0",
    position: "absolute",
    left: "220px",
    padding: "5px 0 5px 10px",
    width: "40px",
    "&::before": {
      border: "14px solid transparent",
      borderRightColor: "#444",
      content: '""',
      left: "-27px",
      position: "absolute",
      top: "1px",
    },
  },
  note: {
    fontWeight: "bold",
    fontSize: "14px",
    margin: "0 9px 0 0",
  },
  note_a: {
    width: "65px",
    backgroundColor: "#339a33",
    "&::before": {
      left: "65px",
      borderColor: "transparent transparent transparent #339a33",
    },
  },
  note_a_co2: {
    backgroundColor: "#f6edfd !important",
    "&::before": {
      borderColor: "transparent transparent transparent #f6edfd !important",
    },
  },
  note_b: {
    width: "85px",
    backgroundColor: "#33cc33",
    "&::before": {
      left: "85px",
      borderColor: "transparent transparent transparent #33cc33",
    },
  },
  note_b_co2: {
    backgroundColor: "#e1c2f8 !important",
    "&::before": {
      borderColor: "transparent transparent transparent #e1c2f8 !important",
    },
  },
  note_c: {
    width: "105px",
    backgroundColor: "#ccff33",
    "&::before": {
      left: "105px",
      borderColor: "transparent transparent transparent #ccff33",
    },
  },
  note_c_co2: {
    backgroundColor: "#d4a9f5 !important",
    "&::before": {
      borderColor: "transparent transparent transparent #d4a9f5 !important",
    },
  },
  note_d: {
    width: "125px",
    backgroundColor: "#ffff00",
    "&::before": {
      left: "125px",
      borderColor: "transparent transparent transparent #ffff00",
    },
  },
  note_d_co2: {
    backgroundColor: "#cb95f3 !important",
    "&::before": {
      borderColor: "transparent transparent transparent #cb95f3 !important",
    },
  },
  note_e: {
    width: "145px",
    backgroundColor: "#ffcc00",
    "&::before": {
      left: "145px",
      borderColor: "transparent transparent transparent #ffcc00",
    },
  },
  note_e_co2: {
    backgroundColor: "#ba72ef !important",
    "&::before": {
      borderColor: "transparent transparent transparent #ba72ef !important",
    },
  },
  note_f: {
    width: "165px",
    backgroundColor: "#ff9a33",
    "&::before": {
      left: "165px",
      borderColor: "transparent transparent transparent #ff9a33",
    },
  },
  note_f_co2: {
    backgroundColor: "#a74deb !important",
    "&::before": {
      borderColor: "transparent transparent transparent #a74deb !important",
    },
  },
  note_g: {
    width: "185px",
    backgroundColor: "#ff0000",
    "&::before": {
      left: "185px",
      borderColor: "transparent transparent transparent #ff0000",
    },
  },
  note_g_co2: {
    backgroundColor: "#8a19df !important",
    "&::before": {
      borderColor: "transparent transparent transparent #8a19df !important",
    },
  },

  lastBlock: {
    marginBottom: 50,
  },
}));

export default useStyles;
