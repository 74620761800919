import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { ImageList } from "@material-ui/core";
import { ImageListItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const PhotoDialog = ({ open, setOpen, id, apiUrl, conf }) => {
  const history = useHistory();
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (!id) return;
    fetch(`${apiUrl}/offrePhotos/${id}`)
      .then((res) => res.json())
      .then((res) => setPhotos(res));
  }, [apiUrl, id]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>Apercu des photos de l'annonce {id}</DialogTitle>
      <DialogContent>
        <ImageList rowHeight={480}>
          {photos.map((photo) => (
            <ImageListItem key={photo}>
              <img
                src={`${conf.IMG_URL}${photo}`}
                alt={`Esset location ${id}`}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </DialogContent>
      <DialogActions>
        <Button autofocus onClick={() => history.push(`/location/${id}`)}>
          Voir l'annonce
        </Button>
        <Button onClick={() => setOpen(false)}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PhotoDialog;
