import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slider from "@material-ui/core/Slider";
import { Button } from "@material-ui/core";
import AutoSuggestLieux from "./AutoSuggestLieux";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankSharpIcon from "@material-ui/icons/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@material-ui/icons/CheckBoxSharp";
import Typography from "@material-ui/core/Typography";

const GreenCheckbox = withStyles({
  root: {
    color: "#5db991",
    "&$checked": {
      color: "#5db991",
    },
  },
  checked: {},
})((props) => (
  <Checkbox
    icon={<CheckBoxOutlineBlankSharpIcon />}
    checkedIcon={<CheckBoxSharpIcon />}
    color="default"
    {...props}
  />
));
const FormFields = ({
  handleSubmit,
  state,
  handleChange,
  setSelectedLieu,
  classes,
  handleBudgetChange,
  handleSearch,
  handleAlertCreation,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <FormGroup row>
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={state.appartement}
              onChange={handleChange}
              name="appartement"
            />
          }
          label="Appartement"
        />
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={state.maison}
              onChange={handleChange}
              name="maison"
            />
          }
          label="Maison"
        />
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={state.parking}
              onChange={handleChange}
              name="parking"
            />
          }
          label="Parking"
        />
      </FormGroup>
      {/* lieu text field */}
      <FormGroup row style={{ marginTop: 10 }}>
        <AutoSuggestLieux setSelectedLieu={setSelectedLieu} />
      </FormGroup>
      {/* Second checkbox row */}
      <FormGroup row className={classes.formGroup}>
        <Typography style={{ paddingTop: 9 }}>Pièces :</Typography>
        <FormControlLabel
          className={classes.label}
          control={
            <GreenCheckbox
              checked={state.studio}
              onChange={handleChange}
              name="studio"
            />
          }
          label="Studio"
        />
        <FormControlLabel
          className={classes.label}
          control={
            <GreenCheckbox
              checked={state.f2}
              onChange={handleChange}
              name="f2"
            />
          }
          label="2"
        />
        <FormControlLabel
          className={classes.label}
          control={
            <GreenCheckbox
              checked={state.f3}
              onChange={handleChange}
              name="f3"
            />
          }
          label="3"
        />
        <FormControlLabel
          className={classes.label}
          control={
            <GreenCheckbox
              checked={state.f4}
              onChange={handleChange}
              name="f4"
            />
          }
          label="4"
        />
        <FormControlLabel
          className={classes.label}
          control={
            <GreenCheckbox
              checked={state.f5}
              onChange={handleChange}
              name="f5"
            />
          }
          label="5 et +"
        />
      </FormGroup>

      {/* Slider row */}
      <FormGroup row style={{ marginTop: 40 }}>
        <FormControlLabel
          control={
            <Slider
              min={0}
              step={50}
              max={15000}
              name="budget"
              value={state.budget}
              onChange={handleBudgetChange}
              aria-labelledby="range-slider"
              // ValueLabelComponent={ValueLabelComponent}
              // This line triggers a React warning due to Transition  + Strict mode being enabled
              // Ignore this warning
              valueLabelDisplay="on"
              style={{ marginLeft: "5%" }}
            />
          }
          name="budget"
          labelPlacement="start"
          label="Budget"
          style={{ marginLeft: 0, width: "95%" }}
          color="secondary"
        />
      </FormGroup>

      {/* Buttons row */}
      <FormGroup row style={{ marginTop: 40 }}>
        <FormControlLabel
          className={classes.buttonContainer}
          control={
            <Button
              variant="contained"
              type="submit"
              // color="primary"
              className={classes.button}
              onClick={handleSearch}
            >
              Rechercher
            </Button>
          }
          name="save"
        />
        <FormControlLabel
          className={classes.buttonContainer}
          control={
            <Button
              variant="contained"
              type="button"
              // color="primary"
              className={classes.button}
              onClick={handleAlertCreation}
            >
              Créer une alerte
            </Button>
          }
          name="save"
        />
      </FormGroup>
    </form>
  );
};

export default FormFields;
