import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isArray, isEmpty } from "lodash";

const r = "Région";
const d = "Département";
const v = "Ville";

const gouvUrl = "https://geo.api.gouv.fr";

function lieu(typeLieu, codeLieu, lieu, population) {
  return population
    ? { typeLieu, codeLieu, lieu, population }
    : { typeLieu, codeLieu, lieu };
}

function sortLieux(a, b) {
  if (a.typeLieu !== b.typeLieu) return -b.typeLieu.localeCompare(a.typeLieu);
  else if (a.typeLieu === v) return a.population < b.population ? 1 : -1;
  return -b.lieu.localeCompare(a.lieu);
}

async function findLieux(text) {
  if (!text || text.trim().length < 2) return;

  let goodText = text.trim();
  if (goodText.trim().length < 2) return;

  return /[0-9]/.test(goodText)
    ? findLieuxByCodePostal(goodText)
    : findLieuxByText(goodText);
}

async function findLieuxByText(text) {
  let res = [];

  try {
    let response = await fetch(`${gouvUrl}/regions?nom=${text}`);
    let body = await response.json();
    body.forEach((e) => res.push(lieu(r, e.code, e.nom)));

    response = await fetch(`${gouvUrl}/departements?nom=${text}`);
    body = await response.json();
    body.forEach((e) => res.push(lieu(d, e.code, e.nom)));

    response = await fetch(
      `${gouvUrl}/communes?nom=${text}&boost=population&limit=20&fields=codesPostaux,population`
    );
    body = await response.json();
    body.forEach((e) =>
      e.codesPostaux.forEach((cp) => res.push(lieu(v, cp, e.nom, e.population)))
    );
  } catch (err) {
    console.error("Failed to findLieuxByText", err);
  }

  return res;
}

async function findLieuxByCodePostal(codePostal) {
  if (codePostal.length !== 2) return;

  let res = [];
  try {
    let response = await fetch(`${gouvUrl}/departements/${codePostal}`);
    let body = await response.json();
    res.push(lieu(d, body.code, body.nom));

    response = await fetch(`${gouvUrl}/departements/${codePostal}/communes`);
    body = await response.json();
    body.forEach((e) =>
      e.codesPostaux.forEach((cp) => res.push(lieu(v, cp, e.nom)))
    );
  } catch (err) {
    console.error("Failed to findLieuxByCodePostal", err);
  }

  return res;
}

export default function AutoSuggestLieux({ setSelectedLieu }) {
  const [lieux, setLieux] = useState([]);

  const handleChange = (event, value) => {
    if (typeof value === "string")
      setSelectedLieu(lieu(v[0].toLowerCase(), "", value));
    else if (value)
      setSelectedLieu(
        lieu(value.typeLieu[0].toLowerCase(), value.codeLieu, value.lieu)
      );
    else setSelectedLieu(value);
  };

  const handleInputChange = (event, value) => {
    findLieux(value)
      .then((data) => {
        if (isArray(data) && !isEmpty(data)) setLieux(data);
      })
      .catch(console.error);
  };

  return (
    <Autocomplete
      id="grouped-lieux"
      // freeSolo
      style={{ backgroundColor: "white" }}
      fullWidth
      options={lieux.sort(sortLieux)}
      groupBy={(option) => option.typeLieu}
      getOptionLabel={(option) =>
        option.lieu ? `${option.lieu} (${option.codeLieu})` : option
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="CP, ville, département ou région"
          variant="outlined"
        />
      )}
      onChange={handleChange}
      onInputChange={handleInputChange}
    />
  );
}
