import MaterialTable, { MTableGroupbar, MTableHeader } from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { forwardRef, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Button, TablePagination, Typography } from "@material-ui/core";
import useStyles from "./style";
import PhotoDialog from "./PhotoDialog";

function PatchedPagination(props) {
  const {
    ActionsComponent,
    onChangePage,
    onChangeRowsPerPage,
    ...tablePaginationProps
  } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={(subprops) => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          // ActionsComponent is provided by material-table
          <ActionsComponent
            {...actionsComponentProps}
            onChangePage={onPageChange}
          />
        );
      }}
    />
  );
}

const ItemsTable = ({ data, apiUrl, budget, conf }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [currentId, setCurrentId] = useState(false);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => (
      <FirstPage
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    LastPage: forwardRef((props, ref) => (
      <LastPage
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    NextPage: forwardRef((props, ref) => (
      <ChevronRight
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    ResetSearch: forwardRef((props, ref) => (
      <Clear
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward
        style={{ color: theme.palette.text.default }}
        {...props}
        ref={ref}
      />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  // listAnnonces = listAnnonces.map((annonce) => {
  //   let output = { ...annonce };

  //   output.customAdress = `${annonce.ville} - ${annonce.adresse1}`;
  //   output.chauffageCollectif = annonce.chauffageCollectif ? (
  //     <Chip label="Oui" variant="outlined" color="primary" />
  //   ) : (
  //     <Chip
  //       label="Non"
  //       variant="outlined"
  //       style={{ color: "red", borderColor: "red" }}
  //     />
  //   );

  //   return output;
  // });

  const columns = [
    {
      title: "Ville",
      field: "ville",
      defaultGroupOrder: 1,
      defaultGroupSort: "asc",
    },
    {
      title: "",
      grouping: false,
      field: "photoCouverture",
      render: (rowData) => {
        return (
          <img
            height="130"
            src={`${conf.IMG_URL}${
              rowData.photoCouverture ? rowData.photoCouverture : "/404.jpg"
            }?s=xs`}
            alt="Bien loué par Esset"
          />
        );
      },
    },
    {
      title: "Typologie",
      grouping: false,
      field: "typeBien",
      render: (rowData) => (
        <span
          className={classes.pointer}
          onClick={() =>
            window.open(`/location/${rowData.codeAnnonce}`, "_blank").focus()
          }
        >
          {rowData.typeBien}
        </span>
      ),
      filtering: false,
      sorting: false,
    },
    {
      title: "Surface",
      grouping: false,
      field: "surface",
      render: (rowData) => `${rowData.surface} m²`,
      filtering: true,
      sorting: true,
    },
    {
      title: "Loyer",
      grouping: false,
      field: "loyerCc",
      render: (rowData) => `${rowData.loyerCc} €`,
      filtering: true,
      sorting: true,
    },
    {
      title: "Annonce",
      grouping: false,
      field: "codeAnnonce",
      filtering: true,
      sorting: true,
    },
    {
      title: "Photos",
      grouping: false,
      field: "nbPhotos",
      render: (rowData) => (
        <Button
          onClick={() => {
            setCurrentId(rowData.codeAnnonce);
            setOpen(true);
          }}
        >
          {rowData.nbPhotos}
        </Button>
      ),
      filtering: true,
      sorting: true,
    },
  ];

  return (
    <>
      <div style={{ margin: "5%" }}>
        <Typography style={{ fontSize: "1.5rem", fontWeight: 500 }}>
          <b>{data.length} annonces</b> correspondent à votre recherche
        </Typography>
        <Typography style={{ fontWeight: 500, fontSize: "0.9rem" }}>
          Esset Property Management vous propose les{" "}
          <b>{data.length} locations </b>
          suivantes correspondant à votre recherche pour un budget compris entre{" "}
          {budget[0]} et {budget[1]} €.
        </Typography>
      </div>
      <MaterialTable
        style={{ marginTop: 10 }}
        title="Annonces groupées"
        icons={tableIcons}
        // onRowClick={(_e, rowData) => handleNumberSearch(_e, rowData.codeAnnonce)}
        localization={{
          grouping: {
            groupedBy: "Annonces groupées par :",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} sur {count}",
            labelRowsSelect: "lignes",
            labelRowsPerPage: "Lignes par page",
            firstAriaLabel: "Première page",
            firstTooltip: "Première page",
            previousAriaLabel: "Page précédente",
            previousTooltip: "Page précédente",
            nextAriaLabel: "Page suivante",
            nextTooltip: "Page suivante",
            lastAriaLabel: "Dernière page",
            lastTooltip: "Dernière page",
          },
          body: {
            emptyDataSourceMessage:
              "Aucun bien ne correspond à votre recherche",
          },
        }}
        columns={columns}
        data={data}
        components={{
          Pagination: PatchedPagination,
          // Remove toolbar
          Toolbar: () => (
            <div
              style={{
                display: "none",
              }}
            ></div>
          ),
          Groupbar: (props) => (
            <div
              style={{
                display: "none",
              }}
            >
              <MTableGroupbar {...props} />
            </div>
          ),
          Header: (props) => <MTableHeader {...props} />,
        }}
        options={{
          grouping: true,
          search: false,
          defaultExpanded: true,
          pageSize: 10,
        }}
      />
      <PhotoDialog
        id={currentId}
        open={open}
        setOpen={setOpen}
        apiUrl={apiUrl}
        conf={conf}
      />
    </>
  );
};

export default ItemsTable;
