import React from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import useStyles from "./style";

const Home = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        {/* First block */}
        <Grid container className={classes.secondBlock}>
          <Grid item lg={6} md={9} xs={12} className={classes.cardContainer}>
            <Typography className={classes.title} variant="h1">
              Plan du site
            </Typography>
            <div>
              <ul className={classes.listContainer}>
                <li>
                  <a href="/">Accueil</a>
                </li>
                <li>
                  <a href="/notre-offre">Nos offres de location</a>
                </li>
                <li>
                  <a href="/nous-contacter">Contactez-nous</a>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Home;
