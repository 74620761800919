import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import useStyles from "./style";

const FinancialConditions = ({ details }) => {
  const classes = useStyles();
  return (
    <Table className={classes.table} aria-label="simple table">
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row" style={{ fontWeight: 600 }}>
            Loyer HC (Hors Charges)
          </TableCell>
          <TableCell align="right">{details.loyerHc} €</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row" style={{ fontWeight: 600 }}>
            Charges
          </TableCell>
          <TableCell align="right">{details.charges} €</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row" style={{ fontWeight: 600 }}>
            Loyer CC (Charges Comprises)
          </TableCell>
          <TableCell align="right">{details.loyerCc} €</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row" style={{ fontWeight: 600 }}>
            Dépôt de garantie
          </TableCell>
          <TableCell align="right">{details.depotGarantie} €</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row" style={{ fontWeight: 600 }}>
            Honoraires TTC à la charge du locataire
          </TableCell>
          <TableCell align="right">{details.honoraires} €</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row" style={{ fontWeight: 600 }}>
          Dont état des lieux
          </TableCell>
          <TableCell align="right">{details.honoraireEtatDesLieuxLocataire} €</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default FinancialConditions;
