import { useContentFetch } from "../../hooks/useContentFetch";
import Grid from "@material-ui/core/Grid";
import useStyles from "./style";
import { Typography } from "@material-ui/core";

const Plaquettes = () => {
  const [content, error, loading, conf] = useContentFetch("plaquettes", true);
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        {error && "Une erreur est survenue"}
        {loading && "Chargement..."}
        {!error && !loading && content !== null && (
          <Grid container className={classes.secondBlock}>
            <Grid item lg={6} md={9} xs={12} className={classes.cardContainer}>
              <Typography className={classes.title} variant="h1">
                Plaquettes
              </Typography>
              <Typography className={classes.paragraph} variant="body1">
                Sommaire
              </Typography>
              <ul className={classes.listContainer}>
                {content.map((plaquette) => (
                  <li key={plaquette.id}>
                    <a
                      href={`${conf.STRAPI_URL}${plaquette.file.url}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {plaquette.title}
                    </a>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Plaquettes;
