import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useConfFetch } from "../../hooks/useConfFetch";

const Unsubscribe = ({ location }) => {
  const [conf, error, loading] = useConfFetch();

  const [isDeleted, setIsDeleted] = useState(false);

  // Retrieve and isolate parameters from URL
  let { search } = location;
  search = search.substring(1);
  let paramsArray = search.split("&");
  paramsArray = paramsArray.map((param) =>
    param.substring(param.indexOf("=") + 1)
  );

  useEffect(() => {
    if (conf && !error && !loading) {
      fetch(
        // Remove whatever is before the "-" char to only send the raw ID
        `${conf.API_URL}/alerteEmail?id=${paramsArray[1].substring(
          paramsArray[1].indexOf("-") + 1
        )}&email=${paramsArray[0]}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log("res", res);
          setIsDeleted(true);
        })
        .catch((err) => {
          console.log("err", err);
          setIsDeleted(true);
        });
    }
  }, [conf, error, loading, paramsArray]);
  return (
    <div style={{ margin: 50 }}>
      {isDeleted ? (
        <Typography variant="h4">
          Votre adresse email {paramsArray[0]} a été supprimée correctement.
        </Typography>
      ) : (
        <Typography variant="h4">
          Désinscription de votre adresse {paramsArray[0]} en cours...
        </Typography>
      )}
    </div>
  );
};

export default Unsubscribe;
