import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: "#f2f2f2",
  },
  title: {
    fontSize: "3rem",
    fontWeight: 500,
    marginBottom: "50px",
    paddingBottom: "2rem",
  },
  imgOffer: {
    [theme.breakpoints.down("sm")]: {
      height: 470,
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "640px",
    },
    height: "100%",
    backgroundSize: "cover",
  },
  button: {
    height: 51,
    backgroundColor: theme.palette.violet.main,
    color: theme.palette.white.main,
    padding: "10px 25px",
    fontSize: "1.3rem",
    textTransform: "none",
  },
  buttonContainer: {
    margin: "auto",
    marginTop: 30,
  },
  input: {
    backgroundColor: theme.palette.white.main,
    height: 40,
  },
  // formGroup: {
  //   "& label": {
  //     // marginRight: 25,
  //   },
  // },
  label: { margin: "0 5px" },
  dialog: {
    position: "absolute !important",
    top: "90px !important",
  },
  dialogTitle: {
    "& h2": {
      fontSize: 16,
    },
  },

  pointer: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  
  highlight: {
    fontWeight: "bold"
  }
}));

export default useStyles;
