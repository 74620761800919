import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  secondBlock: {
    backgroundColor: "#f2f2f2",
  },
  cardContainer: {
    padding: "3%",
  },
  title: {
    fontSize: "3rem",
    fontWeight: 500,
    paddingBottom: "2rem",
  },
  paragraph: {
    maxWidth: "460px",
    fontWeight: 500,
  },
  listContainer: {
    "& a ": { color: theme.palette.green.main, margin: 10 },
    "& li": { margin: "15px 0" },
  },
}));

export default useStyles;
