import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./style";
import { useState } from "react";

const CreateAlertPopup = ({ open, setOpen, handleCreateAlert }) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleChange = (event) => {
    if (
      // eslint-disable-next-line no-useless-escape
      /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/.test(
        event.target.value.toLowerCase()
      )
    ) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }

    setEmail(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      // className={classes.dialog}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        Créer une alerte Esset Property Management
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Recevez par email les nouvelles annonces immobilières de location
          correspondant à vos critères de recherche.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Votre adresse email"
          type="email"
          value={email}
          onChange={(event) => {
            handleChange(event);
          }}
          fullWidth
          required
          helperText={
            isEmailValid ? "" : "Vous devez entre une adresse email valide"
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleCreateAlert(email)}
          style={{ color: isEmailValid ? "green" : "grey" }}
          disabled={isEmailValid ? false : true}
        >
          Créer l'alerte
        </Button>
        <Button onClick={() => setOpen(false)} style={{ color: "red" }}>
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAlertPopup;
