import { AppBar, Grid, Toolbar } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: "#212529",
    backgroundColor: "#5db991",
    height: 111,
    width: "100%",
    paddingTop: 30,
    fontSize: "0.9rem",
  },
  link: {
    display: "inherit",
    marginLeft: 30,
    color: theme.palette.black.main,
  },
  toolbar: {
    width: "60%",
    margin: "auto",
    paddingBottom: 30,
  },
  linkContact: {
    textDecoration: "none"
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <AppBar
      position="static"
      color="primary"
      component="footer"
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Grid container>
          {/* <img
          src={logo}
          width="300"
          alt="Logo Foncia Groupe"
          style={{ margin: "auto" }}
        /> */}
          <Grid item xs={12} md={3}>
            <span style={{ fontWeight: "bold" }} className={classes.link}>
              @Esset {new Date().getFullYear()}
            </span>
          </Grid>
          <Grid item xs={12} md={3}>
            <a href="https://www.esset-pm.com/" className={classes.link}>
                  Qui sommes nous ?
              </a>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link to="/mentions-legales" className={classes.link}>
              Mentions légales
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link to="/plan-du-site" className={classes.link}>
              Plan du site
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
