import { Typography } from "@material-ui/core";
import { Grid, Paper, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
// import Carousel from "react-material-ui-carousel";

import useStyles from "./style";
import FinancialConditions from "./FinancialConditions";
// import Diagnostic from "./Diagnostic";
import DetailTable from "./DetailTable";
import Map from "./Map";
import DOMPurify from "dompurify";

import { useConfFetch } from "../../hooks/useConfFetch";
import { useEffect, useState } from "react";
import { useContentFetch } from "../../hooks/useContentFetch";

import Carousel, { Dots, slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

const Details = ({ match }) => {
  const classes = useStyles();
  const [conf, error, loading] = useConfFetch();
  const [content] = useContentFetch("details");
  const { params } = match;
  const [value, setValue] = useState(0);

  const onChange = (value) => {
    setValue(value);
    console.log("value", value);
  };

  const [details, setDetails] = useState(null);
  const [notFound, setNotFound] = useState(false)
  // const [departement, setDepartement] = useState(null);

  useEffect(() => {
    if (conf && !error && !loading) {
      fetch(`${conf.API_URL}/offre/${params.locationId}`)
        .then((res) => res.json())
        .then((res) => {
          setDetails(res);

          // fetch(
          //   `https://api-adresse.data.gouv.fr/search/?q=${res.ville}&type=municipality&autocomplete=1`
          // )
          //   .then((response) => response.json())
          //   .then((response) => {
          //     let context = response.features[0].properties.context;
          //     setDepartement(context.split(",")[1]);
          //   });
        })
        .catch((err) => {
          console.error("error resource not found");
          setNotFound(true)
        });
    }
  }, [conf, error, loading, params.locationId]);

  return (
    <Grid container className={classes.pageContainer}>
      {details ? (
        <Grid item xs={12}>
          <Grid container className={classes.contentTop}>
            {/* <Grid item>
            </Grid> */}
            <Grid item xs={12} md={9} className={classes.leftContent}>
              <Typography className={classes.codeAnnonce}>
                Annonce N° {details.codeAnnonce}
              </Typography>
              {details.accroche && (
                <Typography className={classes.titleAnnonce}>
                  {details.accroche}
                </Typography>
              )}
              <Typography className={classes.titleAnnonce}>
                {details.nbPieces > 0
                  ? `${details.typeBien.split(" ")[0]} - ${
                      details.nbPieces
                    } pièce${details.nbPieces > 1 ? "s" : ""}`
                  : `${details.typeBien.split(" ")[0]}`}
                {details.nbPieces > 0 && ` - ${details.surface} m²`}
              </Typography>
              <Typography className={classes.titleAnnonce}>
                {details.adresse1} {details.codePostal} - {details.nomProgramme}
              </Typography>
              <Typography className={classes.codeAnnonce}>
                Loyer Charges Comprises : {details.loyerCc}.00 € |{" "}
                {new Date(details.dateDisponibilite) < new Date()
                  ? "Disponible immédiatement"
                  : `Date de disponibilité : ${new Date(
                      details.dateDisponibilite
                    ).toLocaleDateString("fr")}`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.rightContent}>
              <Typography className={classes.codeAnnonce}>
                Cette offre vous intéresse :
              </Typography>
              <Typography className={classes.codeAnnonce}>
                Contact : Esset Property Management
                <br />
                09 69 39 40 55 (APPEL NON SURTAXÉ)
              </Typography>
              <Typography className={classes.codeAnnonce}>
                <Link to={`/nous-contacter/${details.codeAnnonce}`}>
                  Nous contacter par email
                </Link>
              </Typography>
              <Typography className={classes.detailsAndPlanLinks}>
                <a href="#details" style={{ marginRight: 10 }}>
                  Voir les détails du lot
                </a>
                <a href="#plan">Plan d'accès</a>
              </Typography>
              <Typography className={classes.codeAnnonce}>
                Commune : {details.codePostal} - {details.ville}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.imgContainer}>
            <Grid item xs={12} className={classes.carouselContainer}>
              {(details.urlVisite360 || details.urlCertifLuminosite) && (
                <div className={classes.iconContainer}>
                  {details.urlVisite360 && (
                    <div>
                      <a
                        href={details.urlVisite360}
                        className={classes.logo360}
                        target="_blank"
                        rel="noreferrer"
                        title="Visite 360"
                      >
                        {" "}
                      </a>
                    </div>
                  )}
                  {details.urlCertifLuminosite && (
                    <div>
                      <a
                        href={details.urlCertifLuminosite}
                        className={classes.sunIcon}
                        target="_blank"
                        rel="noreferrer"
                        title="Certificat luminosité"
                      >
                        {" "}
                      </a>
                    </div>
                  )}
                </div>
              )}
              <div className={classes.carousel}>
                <Carousel
                  // itemWidth={800}
                  value={value}
                  onChange={onChange}
                  style={{ width: "300px !important" }}
                  plugins={[
                    "arrows",
                    "infinite",
                    "centered",
                    {
                      resolve: slidesToShowPlugin,
                      options: {
                        numberOfSlides: 1,
                      },
                    },
                  ]}
                >
                  {details.photos?.map((photo) => (
                    <Paper key={photo} className={classes.paperImg}>
                      <img
                        src={`${conf.IMG_URL}${photo}`}
                        className={classes.img}
                        // style={{ width: "100%" }}
                        alt="location"
                      />
                    </Paper>
                  ))}
                </Carousel>
                <div className={classes.dots}>
                  <Dots
                    value={value}
                    onChange={onChange}
                    number={details.photos?.length}
                    // s
                  />
                </div>
              </div>
              {/* {details.photos?.map((photo) => (
                    <Paper key={photo} className={classes.paperImg}>
                      <img
                        src={`${conf.IMG_URL}${photo}`}
                        // className={classes.img}
                        alt="location"
                      />
                    </Paper>
                  ))} */}
              {/* </div> */}
              {/* <Carousel
                navButtonsAlwaysVisible={true}
                autoPlay={false}
                swipe={true}
                fullHeightHover={true}
                changeOnFirstRender={true}
                // animation="slide"
              >
                {details.photos?.map((photo) => (
                  <Paper key={photo} className={classes.paperImg}>
                    <img
                      // height="455"
                      className={classes.img}
                      src={`${conf.IMG_URL}${photo}`}
                      alt="img"
                    />
                  </Paper>
                ))}
              </Carousel> */}
            </Grid>
          </Grid>
          <Grid container style={{ paddingLeft: "3%" }}>
            <Grid item xs={12}>
              <Button className={classes.button} variant="contained">
                <a
                  href={
                    content ? `${conf.STRAPI_URL}${content.candidacy.url}` : `#`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Déposer votre candidature
                </a>
              </Button>
              <Grid container style={{ paddingRight: 20 }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h3" className={classes.title}>
                    Description sur la résidence
                  </Typography>
                  <Typography variant="body2" className={classes.paragraph}>
                    {details.descriptifResidence}
                  </Typography>
                  <Typography variant="h3" className={classes.title}>
                    Observations sur le bien
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.paragraph}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(details.descriptifBien),
                    }}
                  />
                  <Typography variant="h3" className={classes.title}>
                    Conditions financières
                  </Typography>
                  <FinancialConditions details={details} />
                </Grid>
                <Grid item xs={12} md={6} style={{ paddingLeft: 20 }}>
                  <Typography
                    variant="h3"
                    className={classes.title}
                    id="details"
                  >
                    Détails
                  </Typography>
                  <DetailTable details={details} />
                </Grid>
                <Grid item xs={12} className={classes.lastBlock}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h3" className={classes.title}>
                        Diagnostics
                      </Typography>
                      <Grid container>
                        {new Date(details.dateCommandeDpe) <
                        new Date("2021-07-01") ? (
                          <>
                            <Grid item xs={12}>
                              <img
                                src={`https://dpe.foncia.net/${details.consoKwhep}/${details.emissionGes}/1/330/330/3/diagrammeEnergie.png`}
                                alt="dpe"
                                style={{ width: "95%", height: "fit-content" }}
                              />
                            </Grid>
                            <Typography
                              variant="body2"
                              className={classes.paragraph}
                            >
                              DPE non opposable réalisé suivant l'ancienne
                              méthode applicable avant le 1er juillet 2021
                            </Typography>
                            {details.depenseAnnuelleEnergie ? (
                              <Typography
                                variant="body2"
                                className={classes.paragraph}
                              >
                                {details.depenseAnnuelleEnergie < 380 &&
                                  `Montant estimé des dépenses annuelles d’énergie pour un usage standard : ${details.depenseAnnuelleEnergie}€.
                                  ${details?.anneeRefDepenseEnergie} est l’année de référence des prix de l’énergie utilisés pour établir cette estimation
                                  `}
                              </Typography>
                            ) : (
                              <Typography
                                variant="body2"
                                className={classes.paragraph}
                              >
                                Montant estimé des dépenses annuelles d’énergie
                                pour un usage standard : non communiqué.
                                <br />
                                {details.anneeRefDepenseEnergie
                                  ? `${details.anneeRefDepenseEnergie} est l’année de référence des prix de l’énergie
                                utilisés pour établir cette estimation.`
                                  : "L'année de référence pour cette estimation n'est pas définie"}
                              </Typography>
                            )}
                          </>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <img
                                src={`https://dpe.foncia.net/v2/${details.consoKwhep}/${details.emissionGes}/1/700/330/3/diagrammeEnergie.png`}
                                alt="dpe"
                                style={{ width: "100%" }}
                              />
                            </Grid>
                            <Typography
                              variant="body2"
                              className={classes.paragraph}
                            >
                              DPE non opposable réalisé suivant la nouvelle
                              méthode applicable après le 1er juillet 2021
                            </Typography>
                            {details.depenseAnnuelleEnergie ? (
                              <Typography
                                variant="body2"
                                className={classes.paragraph}
                              >
                                {details.depenseAnnuelleEnergie > 2620 &&
                                  details.depenseAnnuelleEnergie < 3560 &&
                                  `Montant estimé des dépenses annuelles d’énergie pour un usage standard : ${details.depenseAnnuelleEnergie}€.
                                  ${details?.anneeRefDepenseEnergie} est l’année de référence des prix de l’énergie utilisés pour établir cette estimation
                                  `}
                              </Typography>
                            ) : (
                              <Typography
                                variant="body2"
                                className={classes.paragraph}
                              >
                                Montant estimé des dépenses annuelles d’énergie
                                pour un usage standard : non communiqué.
                                <br />
                                {details.anneeRefDepenseEnergie
                                  ? `${details.anneeRefDepenseEnergie} est l’année de référence des prix de l’énergie
                                utilisés pour établir cette estimation.`
                                  : "L'année de référence pour cette estimation n'est pas définie"}
                              </Typography>
                            )}
                          </>
                        )}
                        <Typography
                            variant="body2"
                            className={classes.paragraph}
                        >
                            <span style={{ fontWeight: "bold" }}>État des risques et pollutions</span> Les informations sur les risques auxquels ce bien est exposé sont disponibles sur le site Géorisques : <a href="https://georisques.gouv.fr" target="_blank" rel="noreferrer">www.georisques.gouv.fr</a>.
                        </Typography>
                        {/* <Grid item xs={12} md={6}>
                          <Diagnostic />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Diagnostic co2={true} />
                        </Grid>
                        <Typography
                          variant="body2"
                          className={classes.paragraph}
                        >
                          DPE non opposable réalisé suivant l'ancienne métode
                          applicable avant le 1er juillet 2021
                        </Typography> */}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ height: "65vh" }}>
                      <Typography
                        variant="h3"
                        className={classes.title}
                        id="plan"
                      >
                        Plan
                      </Typography>
                      <Map details={details} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.lastBlock}>
                  <Typography variant="h3" className={classes.title}>
                    Voir aussi
                  </Typography>
                  <Typography>
                    <Link
                      to={{
                        pathname: `/notre-offre`,
                        searchInfos: {
                          typeLieu: "d",
                          codeLieu: details.codeDepartement,
                          lieu: details.nomDepartement,
                        },
                      }}
                    >
                      Toutes les locations du département{" "}
                      {details.nomDepartement} ({details.codeDepartement})
                    </Link>
                    <Link
                      to={{
                        pathname: "/notre-offre",
                        searchInfos: {
                          typeLieu: "v",
                          codeLieu: details.codePostal,
                          lieu: details.ville,
                        },
                      }}
                      // to={`/notre-offre/${details.ville}/c`}
                      style={{ paddingLeft: 20 }}
                    >
                      Toutes les locations à {details.ville} (
                      {details.codePostal})
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (notFound ? (
        <Typography className={classes.highlight}>
            le lot que vous avez sélectionné n'est plus disponible
        </Typography>
      ) :
        (
        <>Chargement des informations...</>
      ))}
    </Grid>
  );
};

export default Details;
