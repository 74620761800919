import React from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DOMPurify from "dompurify";
import useStyles from "./style";

import { useContentFetch } from "../../hooks/useContentFetch";

const Home = () => {
  const classes = useStyles();
  const [content, error, loading] = useContentFetch("legals");

  return (
    <Grid container>
      <Grid item xs={12}>
        {error && "Une erreur est survenue"}
        {!error && !loading && content !== null ? (
          <Grid container className={classes.secondBlock}>
            <Grid item lg={6} md={9} xs={12} className={classes.cardContainer}>
              <Typography className={classes.title} variant="h1">
                {content.main_title}
              </Typography>
              <Typography className={classes.paragraph} variant="body1">
                Sommaire
              </Typography>
              <ul className={classes.listSommaire}>
                {content.mentions.map((mention) => (
                  <li key={mention.id}>
                    <a href={`#${mention.id}`}>{mention.title}</a>
                  </li>
                ))}
              </ul>
              {content.mentions.map((mention) => (
                <div key={mention.id}>
                  <Typography variant="h4" id={mention.id}>
                    {mention.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.content}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(mention.content),
                    }}
                  />
                </div>
              ))}
            </Grid>
          </Grid>
        ) : (
          "Chargement"
        )}
      </Grid>
    </Grid>
  );
};
export default Home;
