import React from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import useStyles from "./style";

const Home = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        {/* First block */}
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.cardContainer}>
            <Typography className={classes.title} variant="h1">
              Erreur 404
            </Typography>
            <Typography className={classes.paragraph} variant="body1">
              La page que vous demandez n'existe pas.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Home;
