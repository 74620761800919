import React, { useEffect, useState } from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DOMPurify from "dompurify";

import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

import useStyles from "./style";

import { fieldsLeft, fieldsRight } from "./utils";
import { Button } from "@material-ui/core";

import { useContentFetch } from "../../hooks/useContentFetch";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Contact = ({ match }) => {
  const classes = useStyles();
  const { params } = match;
  const [content, error, loading, conf] = useContentFetch("contacts");
  const [captchaValue, setCaptchaValue] = useState("");
  const [state, setState] = useState([...fieldsLeft, ...fieldsRight]);
  const [openErrorCaptcha, setOpenErrorCaptcha] = useState(false);
  const [openEmailSent, setOpenEmailSent] = useState(false);

  useEffect(() => {
    loadCaptchaEnginge(5);
  }, []);

  useEffect(() => {
    if (params.codeAnnonce) state[9].value = params.codeAnnonce;
  }, [params, state]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorCaptcha(false);
    setOpenEmailSent(false);
  };

  const handleChange = (event) => {
    setCaptchaValue(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateCaptcha(captchaValue) === true) {
      // Add here post to backend with state infos
      // Prepare data to send
      const data = {};
      state.forEach((el) => {
        data[el.id] = el.value;
      });
      data.message = DOMPurify.sanitize(data.message);
      console.log("data", data);

      fetch(`${conf.API_URL}/contact`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => {
        if (res.status === 201) {
          loadCaptchaEnginge(5);
          // Reset form
          setCaptchaValue("");
          let updatedData = state;
          updatedData.map((el) => (el.value = ""));
          setState(updatedData);
          setOpenEmailSent(true);
        }
      });

      // setOpenEmailSent(true);
    } else {
      // alert("Le captcha écrit ne correspond pas ");
      setOpenErrorCaptcha(true);
    }
  };

  const handleTextChange = (event) => {
    let updatedData = [...state];
    const index = updatedData.findIndex(
      (field) => field.label === event.target.name
    );
    updatedData[index].value = event.target.value;
    setState(updatedData);
  };

  return (
    <Grid container className={classes.pageContainer}>
      {/* Snackbars for error captcha AND successfully sent email */}
      <Snackbar
        open={openErrorCaptcha}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          Le captcha écrit ne correspond pas
        </Alert>
      </Snackbar>
      <Snackbar
        open={openEmailSent}
        // autoHideDuration={10000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Votre message a été envoyé, notre équipe vous répondra dans les plus
          brefs délais
        </Alert>
      </Snackbar>

      <Grid item xs={12}>
        {/* First block */}
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} className={classes.cardContainer}>
            {error && "Une erreur est survenue"}
            {!error && !loading && content !== null ? (
              <>
                <Typography className={classes.title} variant="h1">
                  {content.main_title}
                </Typography>
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(content.main_paragraph),
                  }}
                />
              </>
            ) : (
              "Chargement"
            )}
            {/* Contact form */}
            <form>
              <Grid container style={{ marginTop: 20 }}>
                <Grid item xs={12} md={6}>
                  {fieldsLeft.map((field, index) => (
                    <React.Fragment key={field.label}>
                      {field.type === "select" ? (
                        <FormControl
                          variant="filled"
                          className={classes.formControl}
                          style={{ margin: 10, paddingRight: 20 }}
                          fullWidth={true}
                        >
                          <InputLabel
                            key={field.id}
                            id="civilite-label"
                            htmlFor="filled-age-native-simple"
                            required={field.mandatory}
                          >
                            {field.label}
                          </InputLabel>
                          <Select
                            labelId="civilite-label"
                            fullWidth={true}
                            variant="outlined"
                            value={state[index].value}
                            onChange={handleTextChange}
                            label={field.label}
                            name={field.label}
                            placeholder={field?.placeholder}
                            inputProps={{
                              name: field.label,
                              id: "filled-age-native-simple",
                            }}
                            className={classes.input}
                          >
                            {field.options.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <div style={{ width: "100%", padding: 10 }}>
                          <TextField
                            value={state[index].value}
                            fullWidth={true}
                            variant="outlined"
                            onChange={handleTextChange}
                            label={field.label}
                            name={field.label}
                            required={field.mandatory}
                            placeholder={field?.placeholder}
                            className={classes.input}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </Grid>
                <Grid item xs={12} md={6}>
                  {fieldsRight.map((field, index) => (
                    <div style={{ padding: 10 }} key={field.label}>
                      <TextField
                        fullWidth={true}
                        value={state[index + 7].value}
                        onChange={handleTextChange}
                        variant="outlined"
                        label={field.label}
                        name={field.label}
                        required={field.mandatory}
                        placeholder={field?.placeholder}
                        multiline={field?.type === "multiline"}
                        className={classes.input}
                        rows={field?.rows || 1}
                      />
                    </div>
                  ))}
                  <Grid container style={{ padding: 10 }}>
                    <Grid item xs={4}>
                      <LoadCanvasTemplate />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        value={captchaValue}
                        onChange={handleChange}
                        fullWidth={true}
                        variant="outlined"
                        label="Captcha"
                        className={classes.input}
                        required={true}
                        placeholder="Reproduisez les caractères du captcha"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                  <Grid justifyContent="center" container>
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      className={classes.button}
                      variant="contained"
                    >
                      Envoyer votre message
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>

            <Grid container>
              <Grid item xs={12}>
                <span style={{ color: "#dc3545" }}>*</span> Champs obligatoires
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 50 }}>
              {error && "Une erreur est survenue"}
              {!error && !loading && content !== null ? (
                <>
                  {content.details.map((detail) => (
                    <React.Fragment key={detail.adress}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className={classes.details}
                        style={{ textAlign: "end", paddingRight: 30 }}
                      >
                        <Typography variant="body1" component="span">
                          {detail.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className={classes.details}
                        key={detail.name}
                      >
                        {detail.adress}
                      </Grid>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                "Chargement"
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Contact;