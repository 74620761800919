import TopMenu from "./Components/TopMenu";
import Footer from "./Components/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Views/Home";
import Offer from "./Views/Offer";
import Legal from "./Views/Legal";
import Plan from "./Views/Plan";
import Error404 from "./Views/Error404";
import Container from "@material-ui/core/Container";
import Details from "./Views/Details";
import Unsubscribe from "./Views/Unsubscribe";
import Plaquettes from "./Views/Plaquettes";
import Contact from "./Views/Contact";

function App() {
  return (
    <div className="App">
      <Container
        // maxWidth="lg"
        style={{
          boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
          padding: 0,
          maxWidth: 1440,
        }}
      >
        <Router>
          <TopMenu />
          <Switch>
            <Route path="/" exact={true} component={Home} />
            <Route path="/notre-offre" component={Offer} />
            <Route path="/location/:locationId" component={Details} />
            <Route path="/nous-contacter/:codeAnnonce?" component={Contact} />
            <Route path="/mentions-legales" component={Legal} />
            <Route path="/plan-du-site" component={Plan} />
            <Route path="/plaquettes" component={Plaquettes} />
            <Route
              path="/desinscription_alertes_email/:email?/:id?"
              component={Unsubscribe}
            />
            <Route component={Error404} />
          </Switch>
          <Footer />
        </Router>
      </Container>
    </div>
  );
}

export default App;
