import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import DOMPurify from "dompurify";

import useStyles from "./style";

import { useContentFetch } from "../../hooks/useContentFetch";

import CreateAlertPopup from "./CreateAlertPopup";
import ItemsTable from "./ItemsTable";
import FormFields from "./FormFields";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Offer = ({ location }) => {
  const [content, error, loading, conf] = useContentFetch("offers");
  const classes = useStyles();
  const { searchInfos } = location;
  const [state, setState] = useState({
    appartement: false,
    maison: false,
    parking: false,
    studio: false,
    f2: false,
    f3: false,
    f4: false,
    f5: false,
    lieu: "",
    typeLieu: "",
    codeLieu: "",
    budget: [0, 15000],
  });
  const [searchData, setSearchData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (!searchInfos) return;

    if (conf && !error && !loading) {
      let data = { ...searchInfos };
      fetchData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInfos, conf, error, loading]);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleBudgetChange = (event, newValue) => {
    setState({ ...state, budget: newValue });
  };

  const fetchData = (data) => {
    fetch(`${conf.API_URL}/offre`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) return;
        setSearchData(res);
      })
      .catch((err) => {
        enqueueSnackbar("Une erreur est survenue: échec de la recherche", { variant: 'error' });
        console.log("err post", err);
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let data = { ...state };
    data.budgetMin = data.budget[0];
    data.budgetMax = data.budget[1];
    delete data.budget;

    if (data.typeLieu === "") delete data.typeLieu;
    if (data.codeLieu === "") delete data.codeLieu;
    if (data.lieu === "") delete data.lieu;
    fetchData(data);
  };

  const handleAlertCreation = (e) => {
    // e.preventDefault();
    setOpen(true);
  };

  const [open, setOpen] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);

  const handleCreateAlert = (email) => {
    let data = { ...state };

    data.email = email;
    data.budgetMin = data.budget[0];
    data.budgetMax = data.budget[1];
    delete data.budget;
    if (data.typeLieu === "") delete data.typeLieu;
    if (data.codeLieu === "") delete data.codeLieu;
    if (data.lieu === "") delete data.lieu;

    fetch(`${conf.API_URL}/alerteEmail`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 201) {
          setOpenSuccessSnackbar(true);
        }
      })
      .catch((err) => {
        console.log("error creating email", err);
      });

    setOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccessSnackbar(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const setSelectedLieu = (selectedLieu) => {
    if (selectedLieu)
      setState({
        ...state,
        typeLieu: selectedLieu.typeLieu,
        codeLieu: selectedLieu.codeLieu,
        lieu: selectedLieu.lieu,
      });
    else setState({ ...state, typeLieu: "", codeLieu: "", lieu: "" });
  };

  return (
    <Grid container>
      {/* Snackbar success Email alert */}
      <Snackbar open={openSuccessSnackbar} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Votre alerte email pour cette recherche a été enregistrée
          correctement.
        </Alert>
      </Snackbar>

      <CreateAlertPopup
        handleCreateAlert={handleCreateAlert}
        open={open}
        setOpen={setOpen}
      />
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            md={searchData ? 4 : 6}
            xs={12}
            style={{ padding: searchData ? "1%" : "4%" }}
            className={classes.cardContainer}
          >
            {error && "Une erreur est survenue"}
            {!error && !loading && content !== null ? (
              <Typography
                className={classes.title}
                variant="h1"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(content.main_title),
                }}
              />
            ) : (
              "Chargement"
            )}
            <FormFields
              handleSubmit={handleSubmit}
              state={state}
              handleChange={handleChange}
              setSelectedLieu={setSelectedLieu}
              classes={classes}
              handleBudgetChange={handleBudgetChange}
              handleSearch={handleSearch}
              handleAlertCreation={handleAlertCreation}
            />
          </Grid>
          {error && "Une erreur est survenue"}
          {loading && "Chargement en cours"}
          {!error && !loading && content !== null && !searchData ? (
            <Grid
              item
              md={6}
              xs={12}
              className={classes.imgOffer}
              style={{
                backgroundImage: `url(${conf.STRAPI_URL}${content.main_image.url}`,
                // height: 800,
              }}
            ></Grid>
          ) : (
            searchData && (
              <Grid item md={8} xs={12}>
                {/* <ListSearchItems
                  data={searchData}
                  budget={state.budget}
                  apiUrl={conf.API_URL}
                /> */}
                <ItemsTable
                  data={searchData}
                  budget={state.budget}
                  apiUrl={conf.API_URL}
                  conf={conf}
                />
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Offer;
