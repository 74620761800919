const details = [
  {
    id: "surface",
    name: "Surface",
  },
  {
    id: "nbPieces",
    name: "Nombre de pièces",
  },
  {
    id: "nbChambres",
    name: "Nb de chambres",
  },
  {
    id: "etage",
    name: "Etage",
  },
  {
    id: "chauffageCollectif",
    name: "Chauffage collectif",
  },
  {
    id: "eauChaudeCollective",
    name: "Eau Chaude collective",
  },
  {
    id: "nbAscenseurs",
    name: "Ascenseur",
  },
  {
    id: "nbParkings",
    name: "Parking",
  },
  {
    id: "nbCaves",
    name: "Cave",
  },
  {
    id: "terrasse",
    name: "Terrasse",
  },
];

export { details };
