import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

// import { useConfFetch } from "./useConfFetch";

const useContentFetch = (path, isPlaquette) => {
  const [conf, setConf] = useState(null);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchConf = async () => {
      setError(false);

      try {
        const result = await fetch("/conf.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => res.json())
          .then((res) => res);
        setConf(result);
      } catch (error) {
        setLoading(false);
        setError(true);
        setConf(error);
        enqueueSnackbar("Une erreur est survenue: Impossible de charger la configuration", { variant: 'error' });
        return [content, error, loading];
      }
    };
    fetchConf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetch(`${conf.STRAPI_URL}/${path}`)
          .then((res) => res.json())
          .then((res) => res);

        setLoading(false);
        setContent(isPlaquette ? result : result[0]);
      } catch {
        setLoading(false);
        setError(true);
        setContent([]);
        enqueueSnackbar("Une erreur est survenue: Chargement impossible depuis Strapi", { variant: 'error' });
      }
    };

    if (conf !== null && !error) fetchData();
  }, [conf, error, isPlaquette, path, enqueueSnackbar]);

  return [content, error, loading, conf];
};

export { useContentFetch };
