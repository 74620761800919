import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  listSommaire: {
    listStyleType: "none",
    padding: 0,
    marginBottom: 40,
    "& a": {
      textDecoration: "none",
      color: theme.palette.green.main,
    },
  },
  secondBlock: {
    backgroundColor: "#f2f2f2",
  },
  cardContainer: {
    padding: "5%",
  },
  title: {
    fontSize: "3rem",
    fontWeight: 500,
    paddingBottom: "2rem",
  },
  paragraph: {
    maxWidth: "460px",
    fontWeight: 500,
  },
  content: {
    margin: "5px 0 30px 0",
  },
}));

export default useStyles;
