import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
// import { desktopLinks } from "./utils";
// import logo from "/logo-header.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginRight: `${theme.spacing(2)} !important`,
      marginTop: `${theme.spacing(1)} !important`,
      // color: theme.palette.white.main,
      // fontWeight: "bold",
      textTransform: "initial !important",
      fontWeight: "500 !important",
      fontSize: "1rem !important",
    },
  })
);

const DesktopMenu = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Button component={Link} to="/" className={classes.button}>
        <img
          src="/logo-header.png"
          width="160"
          className="App-logo"
          alt="logo"
        />
      </Button>
      <Button
        className={classes.button}
        style={{ paddingTop: 10 }}
        key={"/notre-offre"}
        component={Link}
        to={"/notre-offre"}
      >
        Nos offres de location
      </Button>

      <Button
        className={classes.button}
        style={{ paddingTop: 10 }}
        key={"/nous-contacter"}
        component={Link}
        to={"/nous-contacter"}
      >
        Contactez-nous
      </Button>
    </React.Fragment>
  );
};

export default DesktopMenu;
