// import { createMuiTheme } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

const palette = {
  primary: {
    main: "#5db991",
    light: "##E1F2EA",
    dark: "#0c2241",
  },
  secondary: {
    main: "#de7021",
    light: "#FAB49E",
    dark: "#f7866429",
  },
  gray: {
    main: "#c0cce3",
    // main: "#c2c7d0",
    light: "#e9ecef",
    superLight: "#f4f6f9",
    dark: "#212529",
  },
  black: {
    main: "#000000",
    light: "#212529",
  },
  white: {
    main: "#ffffff",
  },
  green: {
    main: "#5db991",
  },
  yellow: {
    main: "#ffc107",
    light: "#fbee91",
  },
  red: {
    main: "#dc3545",
    light: "#fba5a1",
  },
  blue: {
    main: "#1a7de2",
    dark: "#343a40",
  },
  violet: {
    main: "#5D55AE",
  },
  orange: {
    main: "#dc3545",
  },
  background: {
    default: "#ffffff",
  },
};

const typography = {
  h5: {
    fontWeight: 700,
    color: palette.gray.dark,
  },
  h6: {
    fontSize: 12,
    fontWeight: 500,
    color: palette.gray.dark,
  },
  h3: {
    fontSize: 34,
    fontWeight: 500,
    color: palette.gray.dark,
  },
  h4: {
    fontSize: "0.9rem",
    fontWeight: 900,
  },
  subtitle1: {
    fontSize: 12,
    fontWeight: 700,
    color: palette.gray.dark,
  },
  body1: {
    // fontSize: 13,
    color: palette.gray.dark,
    fontWeight: 600,
    fontSize: "0.9rem",
  },
  body2: {
    // fontSize: "0.9rem",
    // color: palette.gray.dark,
    // lineHeight: 1.5,

    fontWeight: 400,
    color: palette.gray.dark,
    fontSize: "0.9rem",
  },
  subtitle2: {
    fontSize: 10,
    color: palette.red.main,
  },
  fontFamily: ["Styrene A Web", "sans-serif"].join(","),
};

const overrides = {
  MuiPickersDay: {
    daySelected: {
      backgroundColor: "#de7021",
    },
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: "transparent",
      color: "black",
      zIndex: 10,
    },
  },
  MuiFormControlLabel: {
    root: {
      marginRight: 15,
    },
  },
  MuiFormLabel: {
    asterisk: {
      color: "#dc3545",
      "&$error": {
        color: "#dc3545",
      },
    },
  },
  MuiButton: {
    textTransform: "lowercase !important",
  },

  MuiInputLabel: {
    root: {
      opacity: 0.7,
    },
  },
  MuiAutocomplete: {
    inputRoot: {
      borderRadius: 0,
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 0,
    },
  },
  MuiSlider: {
    thumb: {
      borderRadius: 0,
      "& > span": {
        "& > span": {
          backgroundColor: palette.primary.main,
          fontSize: "9px",
          fontWeight: 700,
        },
      },
    },
  },
  MuiTableCell: {
    head: {
      backgroundColor: `${palette.primary.main} !important`,
      color: `white !important`,
      fontWeight: 800,
    },
    alignLeft: {
      "&:first-of-type": {
        backgroundColor: `#E1F2EA !important`,
      },
    },
  },
  MuiMenu: {
    list: {
      display: "flex",
      flexDirection: "column",
    },
  },
  BrainhubCarousel__container: {
    width: "90vw",
  },
};

const theme = createTheme({
  palette,
  typography,
  overrides,
});

export default theme;
