import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  triangle: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      background:
        "#f2f2f2 linear-gradient(to top left, #f2f2f2 0%, #f2f2f2 50%, #de834f 50%, #de834f 100%) no-repeat",
      backgroundSize: "100% 100%",
      padding: "0 2.5rem",
      minHeight: "764px",
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      background:
        "linear-gradient(to top left, #f2f2f2 0%, #f2f2f2 50%, #de834f 50%, #de834f 100%)",
      backgroundSize: "200% 100%",
      minHeight: "710px",
    },
  },
  secondBlock: {
    backgroundColor: "#f2f2f2",
  },
  cardContainer: {
    padding: "5%",
  },
  title: {
    fontSize: "3rem",
    maxWidth: 400,
    fontWeight: 500,
    paddingBottom: "2rem",
  },
  paragraph: {
    maxWidth: "460px",
    fontWeight: 500,
  },
  img: {
    maxWidth: "100%",
    height: "auto",
  },
  drawContainer: {
    position: "relative",
    width: "100%",
    minHeight: "1px",
    paddingRight: "15px",
    paddingLeft: "15px",
    fontSize: "0.9rem",
  },
  drawTitle: {
    textAlign: "center",
    display: "block",
    fontWeight: 750,
  },
  firstLine: {
    height: "190px",
    display: "block",
    "& > hr": {
      borderLeft: "1px solid black",
      height: "150px",
      position: "absolute",
      left: "50%",
    },
  },
  orangeContainer: {
    width: "149px",
    margin: "auto",
    fontWeight: 500,
  },
  shapeImg: {
    textAlign: "center",
    display: "block",
    height: 139,
    fontWeight: 500,
  },
  tertiaireContainer: {
    marginRight: -15,
    marginLeft: -15,
  },
  littleVertLign: {
    height: "50px",
    display: "block",
    "& > hr": {
      borderLeft: "1px solid black",
      height: "50px",
      position: "absolute",
      left: "50%",
    },
  },
  horizLign: {
    "& > hr": {
      borderTop: "1px solid black",
      height: "1px",
      margin: "6px 75px 10px",
    },
  },
  blueGreenContainer: {
    position: "relative",
    width: "100%",
    minHeight: 1,
    paddingRight: "15px",
    paddingLeft: "15px",
  },
  vertBlue: {
    display: "block",
    height: 120,
    "& > hr": {
      left: 90,
      borderLeft: "1px solid black",
      position: "absolute",
      top: "-18px",
      height: 100,
    },
  },
  vertGreen: {
    display: "block",
    height: 120,
    "& > hr": {
      right: 60,
      borderLeft: "1px solid black",
      position: "absolute",
      top: "-18px",
      height: 100,
    },
  },
  blueShapeContainer: {
    width: "150px",
    textAlign: "left",
    float: "left",
  },
  greenShapeContainer: {
    width: "105px",
    textAlign: "right",
    float: "right",
  },
}));

export default useStyles;
