import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: "#f2f2f2",
  },
  title: {
    fontSize: "3rem",
    fontWeight: 500,
    paddingBottom: "2rem",
  },
  cardContainer: {
    padding: "5%",
  },
  button: {
    height: 51,
    backgroundColor: theme.palette.violet.main,
    color: theme.palette.white.main,
    padding: "10px 25px",
    fontSize: "1.3rem",
    textTransform: "none",
  },
  buttonContainer: {
    margin: "auto",
    marginTop: 30,
  },
  details: {
    marginTop: 10,
  },
  input: {
    backgroundColor: theme.palette.white.main,
  },
}));

export default useStyles;