// import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { useEffect, useState } from "react";
// import { useConfFetch } from "../../hooks/useConfFetch";
import Geocode from "react-geocode";
// import { Marker, Popup, TileLayer } from "react-leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

// import VectorGrid from 'react-leaflet-vectorgrid';
// import vectorTileLayer from 'leaflet-vector-tile-layer';
// import VectorTileLayer from "react-leaflet-vector-tile-layer";

const Map = ({ details }) => {
  // const [apiKey, setApiKey] = useState(null);
  const [center, setCenter] = useState(null);
  // const [conf, error, loading] = useConfFetch();

  useEffect(() => {
    // set response language.
    Geocode.setLanguage("fr");
    // set response region, get french towns first
    Geocode.setRegion("fr");
    // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
    //  ROOFTOP param returns the most accurate result.
    Geocode.setLocationType("ROOFTOP");
    // Enable or disable logs. Its optional.
    // Geocode.enableDebug();
  }, []);

  // useEffect(() => {
  //   if (conf && !error && !loading) {
  //     setApiKey(conf.GOOGLE_MAPS_API_KEY);
  //     // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
  //     Geocode.setApiKey(conf.GOOGLE_MAPS_API_KEY);

  //     // Get latitude & longitude from address.
  //     Geocode.fromAddress(details.adress1 + " " + details.ville).then(
  //       (response) => {
  //         console.log("response lat long?", response);
  //         const { lat, lng } = response.results[0].geometry.location;
  //         setCenter({ lat: lat, lng: lng });
  //       },
  //       (error) => {
  //         console.error("error", error);
  //       }
  //     );
  //   }
  // }, [conf, error, loading, details]);

  useEffect(() => {
    if (!details) return;
    if (details.lat && details.lon) {
      setCenter([details.lat, details.lon]);
      return;
    }
    // https://nominatim.openstreetmap.org/search?q=135+pilkington+avenue,+birmingham&format=json&polygon=1&addressdetails=1
    fetch(
      `https://nominatim.openstreetmap.org/search?q=${details.adresse1},+${details.ville}&format=json&polygon=1&addressdetails=1`
    )
      .then((res) => res.json())
      .then((res) => {
        if (!res.length) return;
        let data = [parseFloat(res[0].lat), parseFloat(res[0].lon)];
        setCenter(data);
      })
      .catch((err) => console.error("error lat long", err));
  }, [details]);

  // return apiKey ? (
  //   <LoadScript googleMapsApiKey={apiKey}>
  //     <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
  //       <Marker position={center} />
  //     </GoogleMap>
  //   </LoadScript>
  // ) : (
  //   <></>
  // );

  //   const options = {
  //     type: 'slicer',
  //     // data: {geojson},
  //     idField: 'OBJECTID',
  //     tooltip: 'NAME',
  //     popup: (layer) => `<div>${layer.properties.NAME}</div>`,
  //     style: {
  //         weight: 0.5,
  //         opacity: 1,
  //         color: '#ccc',
  //         fillColor: '#390870',
  //         fillOpacity: 0.6,
  //         fill: true,
  //         stroke: true
  //     },
  //     hoverStyle: {
  //         fillColor: '#390870',
  //         fillOpacity: 1
  //     },
  //     activeStyle: {
  //         fillColor: '#390870',
  //         fillOpacity: 1
  //     },
  //     zIndex: 401
  // };
  // const tileLayer = vectorTileLayer("https://{s}.osm.preprod.fonciatech.net/{z}/{x}/{y}.pbf", {});

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {/* <MapContainer center={[40.505, -100.09]} zoom={13}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer> */}
      {!center ? (
        "Localisation non disponible"
      ) : (
        <MapContainer
          center={center}
          zoom={13}
          scrollWheelZoom={false}
          style={{ height: "80%", width: "100%" }}
        >
          <TileLayer
            attribution='<a href="https://esset-pm.com/">Esset</a>'
            // url="https://{s}.osm.preprod.fonciatech.net/{z}/{x}/{y}.pbf"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // subdomains={[
            //   "a",
            //   "b",
            //   "c",
            //   // "https://a.osm.preprod.fonciatech.net/{z}/{x}/{y}.pbf",
            //   // "https://b.osm.preprod.fonciatech.net/{z}/{x}/{y}.pbf",
            //   // "https://c.osm.preprod.fonciatech.net/{z}/{x}/{y}.pbf",
            // ]}
          />
          <Marker position={center}>
            <Popup>
              {details.adresse1} - {details.ville} <br /> {details.nomProgramme}
            </Popup>
          </Marker>
        </MapContainer>
      )}
    </div>
  );
};

export default Map;
