const fieldsLeft = [
    {
      value: "",
      id: "civilite",
      label: "Civilité",
      mandatory: true,
      type: "select",
      options: ["Mr", "Mme", "Mlle"],
      placeholder: "Choix civilité",
    },
  
    { value: "", id: "prenom", label: "Prénom", mandatory: true, type: "text" },
    { value: "", id: "nom", label: "Nom", mandatory: true, type: "text" },
    {
      value: "",
      id: "adresse",
      label: "Adresse",
      mandatory: false,
      type: "text",
    },
    {
      value: "",
      id: "adresseSuite",
      label: "Adresse complémentaire",
      mandatory: false,
      type: "text",
    },
    {
      value: "",
      id: "codePostal",
      label: "Code postal",
      mandatory: false,
      type: "text",
    },
    { value: "", id: "ville", label: "Ville", mandatory: false, type: "text" },
  ];
  
  const fieldsRight = [
    {
      value: "",
      id: "telephone",
      label: "Téléphone",
      mandatory: true,
      type: "text",
      placeholder: "Ex : 0601020304",
    },
    { value: "", id: "email", label: "Email", mandatory: true, type: "text" },
    {
      value: "",
      id: "codeAnnonce",
      label: "Code annonce",
      mandatory: false,
      type: "text",
    },
    {
      value: "",
      id: "message",
      label: "Votre message",
      mandatory: true,
      type: "multiline",
      rows: 10,
    },
  ];
  
  export { fieldsLeft, fieldsRight };
  