import React, { useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

const useStyles = makeStyles((theme) =>
  createStyles({
    homeButton: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      fontSize: "15px",
      color: theme.palette.white.main,
      textDecoration: "none",
    },
    drawerButton: {
      fontSize: "20px",
      color: "black",
      textDecoration: "none",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      position: "fixed",
      right: "0px",
      top: "5px",
    },
    list: {
      width: "100%",
      paddingLeft: 10,
      backgroundColor: "#5db991",
    },
    title: {
      marginLeft: "40px",
    },
    listContainer: {
      backgroundColor: "#5db991",
    },
  })
);

const MobileMenu = ({ isAdmin, SignButton }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(!openDrawer);
  };
  return (
    <>
      <Button component={Link} to="/" className={classes.button}>
        <img
          src="/logo-header.png"
          width="160"
          className="App-logo"
          alt="logo"
        />
      </Button>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      {/* <Button className={classes.title}>
        <Link to="/" className={classes.homeButton}>
          Esset
        </Link>
      </Button> */}
      <SwipeableDrawer
        anchor="top"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        // className={classes.listContainer}
      >
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem button>
              <Link to="/" className={classes.drawerButton}>
                <ListItemText>Esset</ListItemText>
              </Link>
            </ListItem>
          </List>
          <Divider />
          <List className={classes.list}>
            <Link
              key={"/notre-offre"}
              to={"/notre-offre"}
              className={classes.drawerButton}
              color="inherit"
            >
              <ListItem button >
                <ListItemText>Nos offres de location</ListItemText>
              </ListItem>
            </Link>
            <Link
              key={"/nous-contacter"}
              to={"/nous-contacter"}
              className={classes.drawerButton}
              color="inherit"
            >
              <ListItem button >
                <ListItemText>Contactez-nous</ListItemText>
              </ListItem>
            </Link>
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenu;
