import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import useStyles from "./style";
import { details as canvas } from "./utils";

const DetailTable = ({ details }) => {
  const classes = useStyles();

  const interpretContent = (detail) => {
    if (["nbParkings", "nbAscenseurs", "nbCaves"].includes(detail.id)) {
      return details[detail.id] > 0 ? "Oui" : "Non";
    }
    if (typeof details[detail.id] === "boolean")
      return details[detail.id] ? "Oui" : "Non";
    return details[detail.id];
  };

  return (
    <Table className={classes.table} aria-label="simple table">
      <TableBody>
        {canvas.map((detail) => (
          <TableRow key={detail.name}>
            <TableCell component="th" scope="row" style={{ fontWeight: 600 }}>
              {detail.name}
            </TableCell>
            <TableCell align="left">
              {interpretContent(detail)}
              {detail.id === "surface" && " m²"}
              {detail.id === "nbPieces" && " pièces"}
              {detail.id === "nbChambres" && " chambres"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DetailTable;
