import React from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DOMPurify from "dompurify";
import useStyles from "./style";

import { useContentFetch } from "../../hooks/useContentFetch";
import { CircularProgress } from "@material-ui/core";

const Home = () => {
  const classes = useStyles();
  const [content, error, loading, conf] = useContentFetch("homes");
  return (
    <Grid container>
      <Grid item xs={12}>
        {error ? <Grid container className={classes.triangle}><Typography sx={{ fontSize: 20 }} gutterBottom>Une erreur est survenue !!</Typography></Grid>
          :
          <>
            {loading && <Grid container className={classes.triangle}><Typography sx={{ fontSize: 14 }} gutterBottom>Chargement... <CircularProgress size={20} /></Typography></Grid>}
            {content !== null &&
              <Grid container className={classes.triangle}>
                <Grid item xs={12} md={6} className={classes.cardContainer}>
                  <Typography className={classes.title} variant="h1">
                    {content.main_title}
                  </Typography>
                  <Typography
                    className={classes.paragraph}
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content.main_paragraph),
                    }}
                  ></Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.cardContainer}
                  style={{ textAlign: "center" }}
                >
                  <img
                    src={`${conf.STRAPI_URL}${content.main_image.url}`}
                    className={classes.img}
                    alt="Esset immeuble"
                  />
                </Grid>
              </Grid>
            }
            {content && (content.second_title || content.second_paragraph) &&
              <Grid container className={classes.secondBlock}>
                <Grid
                  item
                  md={5}
                  xs={12}
                  className={classes.cardContainer}
                  style={{ maxWidth: 535 }}
                >
                  <Typography className={classes.title} variant="h2">
                    {content.second_title}
                  </Typography>
                  <Typography className={classes.paragraph} variant="body1">
                    {content.second_paragraph}
                  </Typography>
                </Grid>
              </Grid>
            }
          </>
        }
      </Grid>
    </Grid>
  );
};
export default Home;
