import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#f2f2f2",
  },
  title: {
    fontSize: "3rem",
    maxWidth: 400,
    fontWeight: 500,
    paddingBottom: "2rem",
  },
  paragraph: {
    maxWidth: "460px",
    fontWeight: 500,
  },
  cardContainer: {
    padding: "5%",
  },
}));

export default useStyles;
