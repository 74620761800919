import React from "react";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbarContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
      // paddingTop: 15,
    },
    appbar: {
      backgroundColor: "white !important",
      boxShadow: "none !important",
      [theme.breakpoints.up("md")]: {
        height: "87px !important",
      },
    },
    toolbar: {
      // backgroundColor: theme.palette.white.main,
    },
  })
);

const TopMenu = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarContainer}>
          {isWide ? <DesktopMenu /> : <MobileMenu />}
          {/* {SignButton} */}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopMenu;
